// SliceZone.js file 

import * as React from 'react'
import {ImagesSlider} from '../slices/ImagesSlider'

export const SliceZone = ({ sliceZone }) => {
  const sliceComponents = {
    images_slider: ImagesSlider,
  }

  const sliceZoneContent = sliceZone.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type]
    if (SliceComponent) {
      return <SliceComponent slice={slice} key={`slice-${index}`} />
    }
    return null
  })

  return <main className="container">{sliceZoneContent}</main>
}

