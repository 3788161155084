import * as React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import Sidebar from '../components/sidebar'
import parse from 'html-react-parser'
import { SliceZone } from '../components/SliceZone'
import VoipFeature from '../components/voipFeature'
import SEO from '../components/seo'

const SingleCarattersistiche = ({ data }) => {
  if (!data) return null
  const post = data.prismicCaratteristiche

  return (
    <Layout>
      <SEO title={post.data.titolo_seo.text} description={post.data.descrizione_seo.text} />
      <section className="px-6 py-12">
        <div className="container mx-auto">
          <div className="flex gap-12 flex-wrap-reverse">
            <aside className="w-full lg:w-3/12">
              <Sidebar />
            </aside>
            <div className="article w-full lg:w-8/12">
              <span className="tag">{post.type}</span>
              <h1 className="text-persian-green-500 text-4xl mb-6">{post.data.titolo.text}</h1>
              <div className="entry prose prose-persian-green max-w-none">
                {parse(post.data.contenuto.html)}
              </div>
              <div>
                <SliceZone sliceZone={post.data.body} />
              </div>

              {post.uid === "centralino-telefonico-cloud-evoluto" && (
                <VoipFeature />
              )}

            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SingleCarattersistiche

export const pageQuery = graphql`
  query PostBySlug($uid: String!) {
    prismicCaratteristiche(uid: { eq: $uid }) {
      uid
      data {
        titolo {
          text
        }
        sottotitolo {
          text
        }
        contenuto {
          html
        }
        titolo_seo {
          text
        }
        descrizione_seo{
          text
        }
        body {
          ... on PrismicCaratteristicheDataBodyImagesSlider {
            slice_type
            id
            items {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                    )
                  }
                }
                alt
              }
              description {
                text
              }
            }
          }
        }
      }
      type
    }
  }
`