import * as React from 'react'
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export const ImagesSlider = ({ slice }) => {

    return (
        <div className="mt-12">
            <Swiper
                modules={[Pagination]}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                pagination={{ clickable: true }}
            >
                {slice.items.map((item, index) => {
                    const image = getImage(item.image.localFile)
                    return (

                        <SwiperSlide key={index}>
                            <span className=" block text-center text-xs">{item.image.alt}</span>
                            <GatsbyImage image={image} alt={item.image.alt} />
                        
                        </SwiperSlide>
                    )
                })}
            </Swiper>

        </div>
    )
}